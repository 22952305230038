/* Import the base boostrap styles */
/*!
 * Bootstrap v2.3.2
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
a:hover,
a:active {
  outline: 0;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  width: auto\9;
  /* IE7-8 need help adjusting responsive images */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.google-maps img {
  max-width: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  background-color: #fff;
}
a {
  color: #325bff;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #002ee4;
  text-decoration: underline;
}
.img-rounded {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.img-circle {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}
.row {
  margin-left: -20px;
  *zoom: 1;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
[class*="span"] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}
.container,
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.span12 {
  width: 940px;
}
.span11 {
  width: 860px;
}
.span10 {
  width: 780px;
}
.span9 {
  width: 700px;
}
.span8 {
  width: 620px;
}
.span7 {
  width: 540px;
}
.span6 {
  width: 460px;
}
.span5 {
  width: 380px;
}
.span4 {
  width: 300px;
}
.span3 {
  width: 220px;
}
.span2 {
  width: 140px;
}
.span1 {
  width: 60px;
}
.offset12 {
  margin-left: 980px;
}
.offset11 {
  margin-left: 900px;
}
.offset10 {
  margin-left: 820px;
}
.offset9 {
  margin-left: 740px;
}
.offset8 {
  margin-left: 660px;
}
.offset7 {
  margin-left: 580px;
}
.offset6 {
  margin-left: 500px;
}
.offset5 {
  margin-left: 420px;
}
.offset4 {
  margin-left: 340px;
}
.offset3 {
  margin-left: 260px;
}
.offset2 {
  margin-left: 180px;
}
.offset1 {
  margin-left: 100px;
}
.row-fluid {
  width: 100%;
  *zoom: 1;
}
.row-fluid:before,
.row-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.row-fluid:after {
  clear: both;
}
.row-fluid [class*="span"] {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-left: 2.12765957%;
  *margin-left: 2.07446809%;
}
.row-fluid [class*="span"]:first-child {
  margin-left: 0;
}
.row-fluid .controls-row [class*="span"] + [class*="span"] {
  margin-left: 2.12765957%;
}
.row-fluid .span12 {
  width: 100%;
  *width: 99.94680851%;
}
.row-fluid .span11 {
  width: 91.4893617%;
  *width: 91.43617021%;
}
.row-fluid .span10 {
  width: 82.9787234%;
  *width: 82.92553191%;
}
.row-fluid .span9 {
  width: 74.46808511%;
  *width: 74.41489362%;
}
.row-fluid .span8 {
  width: 65.95744681%;
  *width: 65.90425532%;
}
.row-fluid .span7 {
  width: 57.44680851%;
  *width: 57.39361702%;
}
.row-fluid .span6 {
  width: 48.93617021%;
  *width: 48.88297872%;
}
.row-fluid .span5 {
  width: 40.42553191%;
  *width: 40.37234043%;
}
.row-fluid .span4 {
  width: 31.91489362%;
  *width: 31.86170213%;
}
.row-fluid .span3 {
  width: 23.40425532%;
  *width: 23.35106383%;
}
.row-fluid .span2 {
  width: 14.89361702%;
  *width: 14.84042553%;
}
.row-fluid .span1 {
  width: 6.38297872%;
  *width: 6.32978723%;
}
.row-fluid .offset12 {
  margin-left: 104.25531915%;
  *margin-left: 104.14893617%;
}
.row-fluid .offset12:first-child {
  margin-left: 102.12765957%;
  *margin-left: 102.0212766%;
}
.row-fluid .offset11 {
  margin-left: 95.74468085%;
  *margin-left: 95.63829787%;
}
.row-fluid .offset11:first-child {
  margin-left: 93.61702128%;
  *margin-left: 93.5106383%;
}
.row-fluid .offset10 {
  margin-left: 87.23404255%;
  *margin-left: 87.12765957%;
}
.row-fluid .offset10:first-child {
  margin-left: 85.10638298%;
  *margin-left: 85%;
}
.row-fluid .offset9 {
  margin-left: 78.72340426%;
  *margin-left: 78.61702128%;
}
.row-fluid .offset9:first-child {
  margin-left: 76.59574468%;
  *margin-left: 76.4893617%;
}
.row-fluid .offset8 {
  margin-left: 70.21276596%;
  *margin-left: 70.10638298%;
}
.row-fluid .offset8:first-child {
  margin-left: 68.08510638%;
  *margin-left: 67.9787234%;
}
.row-fluid .offset7 {
  margin-left: 61.70212766%;
  *margin-left: 61.59574468%;
}
.row-fluid .offset7:first-child {
  margin-left: 59.57446809%;
  *margin-left: 59.46808511%;
}
.row-fluid .offset6 {
  margin-left: 53.19148936%;
  *margin-left: 53.08510638%;
}
.row-fluid .offset6:first-child {
  margin-left: 51.06382979%;
  *margin-left: 50.95744681%;
}
.row-fluid .offset5 {
  margin-left: 44.68085106%;
  *margin-left: 44.57446809%;
}
.row-fluid .offset5:first-child {
  margin-left: 42.55319149%;
  *margin-left: 42.44680851%;
}
.row-fluid .offset4 {
  margin-left: 36.17021277%;
  *margin-left: 36.06382979%;
}
.row-fluid .offset4:first-child {
  margin-left: 34.04255319%;
  *margin-left: 33.93617021%;
}
.row-fluid .offset3 {
  margin-left: 27.65957447%;
  *margin-left: 27.55319149%;
}
.row-fluid .offset3:first-child {
  margin-left: 25.53191489%;
  *margin-left: 25.42553191%;
}
.row-fluid .offset2 {
  margin-left: 19.14893617%;
  *margin-left: 19.04255319%;
}
.row-fluid .offset2:first-child {
  margin-left: 17.0212766%;
  *margin-left: 16.91489362%;
}
.row-fluid .offset1 {
  margin-left: 10.63829787%;
  *margin-left: 10.53191489%;
}
.row-fluid .offset1:first-child {
  margin-left: 8.5106383%;
  *margin-left: 8.40425532%;
}
[class*="span"].hide,
.row-fluid [class*="span"].hide {
  display: none;
}
[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  float: right;
}
.container {
  margin-right: auto;
  margin-left: auto;
  *zoom: 1;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
  *zoom: 1;
}
.container-fluid:before,
.container-fluid:after {
  display: table;
  content: "";
  line-height: 0;
}
.container-fluid:after {
  clear: both;
}
p {
  margin: 0 0 10px;
}
.lead {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 200;
  line-height: 30px;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}
.muted {
  color: #999;
}
a.muted:hover,
a.muted:focus {
  color: #808080;
}
.text-warning {
  color: #c09853;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #a47e3c;
}
.text-error {
  color: #EA526F;
}
a.text-error:hover,
a.text-error:focus {
  color: #e42549;
}
.text-info {
  color: #3a87ad;
}
a.text-info:hover,
a.text-info:focus {
  color: #2d6987;
}
.text-success {
  color: #468847;
}
a.text-success:hover,
a.text-success:focus {
  color: #356635;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 20px;
  color: inherit;
  text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #999;
}
h1,
h2,
h3 {
  line-height: 40px;
}
h1 {
  font-size: 38.5px;
}
h2 {
  font-size: 31.5px;
}
h3 {
  font-size: 24.5px;
}
h4 {
  font-size: 17.5px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 11.9px;
}
h1 small {
  font-size: 24.5px;
}
h2 small {
  font-size: 17.5px;
}
h3 small {
  font-size: 14px;
}
h4 small {
  font-size: 14px;
}
.page-header {
  padding-bottom: 9px;
  margin: 20px 0 30px;
  border-bottom: 1px solid #eee;
}
ul,
ol {
  padding: 0;
  margin: 0 0 10px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: 20px;
}
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
}
ul.inline > li,
ol.inline > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 20px;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 10px;
}
.dl-horizontal {
  *zoom: 1;
}
.dl-horizontal:before,
.dl-horizontal:after {
  display: table;
  content: "";
  line-height: 0;
}
.dl-horizontal:after {
  clear: both;
}
.dl-horizontal dt {
  float: left;
  width: 160px;
  clear: left;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dl-horizontal dd {
  margin-left: 180px;
}
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #999;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}
blockquote p {
  margin-bottom: 0;
  font-size: 17.5px;
  font-weight: 300;
  line-height: 1.25;
}
blockquote small {
  display: block;
  line-height: 20px;
  color: #999;
}
blockquote small:before {
  content: '\2014 \00A0';
}
blockquote.pull-right {
  float: right;
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eee;
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small {
  text-align: right;
}
blockquote.pull-right small:before {
  content: '';
}
blockquote.pull-right small:after {
  content: '\00A0 \2014';
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
address {
  display: block;
  margin-bottom: 20px;
  font-style: normal;
  line-height: 20px;
}
code,
pre {
  padding: 0 3px 2px;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 12px;
  color: #333;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
code {
  padding: 2px 4px;
  color: #d14;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  white-space: nowrap;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
pre.prettyprint {
  margin-bottom: 20px;
}
pre code {
  padding: 0;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
form {
  margin: 0 0 20px;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 40px;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
legend small {
  font-size: 15px;
  color: #999;
}
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
input,
button,
select,
textarea {
  font-family: 'Roboto', Arial, sans-serif;
}
label {
  display: block;
  margin-bottom: 5px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
input[type=number_manual] {
  display: inline-block;
  height: 20px;
  padding: 4px 6px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  vertical-align: middle;
}
input,
textarea,
.uneditable-input {
  width: 206px;
}
textarea {
  height: auto;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
input[type=number_manual] {
  background-color: #fff;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear .2s, box-shadow linear .2s;
  -moz-transition: border linear .2s, box-shadow linear .2s;
  -o-transition: border linear .2s, box-shadow linear .2s;
  transition: border linear .2s, box-shadow linear .2s;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  *margin-top: 0;
  /* IE7 */
  margin-top: 1px \9;
  /* IE8-9 */
  line-height: normal;
}
input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}
select,
input[type="file"] {
  height: 30px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
  *margin-top: 4px;
  /* For IE7, add top margin to align select with labels */
  line-height: 30px;
}
select {
  width: 220px;
  border: 1px solid #ccc;
  background-color: #fff;
}
select[multiple],
select[size] {
  height: auto;
}
select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.uneditable-input,
.uneditable-textarea {
  color: #999;
  background-color: #fcfcfc;
  border-color: #ccc;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
  cursor: not-allowed;
}
.uneditable-input {
  overflow: hidden;
  white-space: nowrap;
}
.uneditable-textarea {
  width: auto;
  height: auto;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
}
.radio,
.checkbox {
  min-height: 20px;
  padding-left: 20px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
  float: left;
  margin-left: -20px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
  padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
  float: none;
  margin-left: 0;
}
.input-append input[class*="span"],
.input-append .uneditable-input[class*="span"],
.input-prepend input[class*="span"],
.input-prepend .uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"],
.row-fluid .input-prepend [class*="span"],
.row-fluid .input-append [class*="span"] {
  display: inline-block;
}
input,
textarea,
.uneditable-input {
  margin-left: 0;
}
.controls-row [class*="span"] + [class*="span"] {
  margin-left: 20px;
}
input.span12,
textarea.span12,
.uneditable-input.span12 {
  width: 926px;
}
input.span11,
textarea.span11,
.uneditable-input.span11 {
  width: 846px;
}
input.span10,
textarea.span10,
.uneditable-input.span10 {
  width: 766px;
}
input.span9,
textarea.span9,
.uneditable-input.span9 {
  width: 686px;
}
input.span8,
textarea.span8,
.uneditable-input.span8 {
  width: 606px;
}
input.span7,
textarea.span7,
.uneditable-input.span7 {
  width: 526px;
}
input.span6,
textarea.span6,
.uneditable-input.span6 {
  width: 446px;
}
input.span5,
textarea.span5,
.uneditable-input.span5 {
  width: 366px;
}
input.span4,
textarea.span4,
.uneditable-input.span4 {
  width: 286px;
}
input.span3,
textarea.span3,
.uneditable-input.span3 {
  width: 206px;
}
input.span2,
textarea.span2,
.uneditable-input.span2 {
  width: 126px;
}
input.span1,
textarea.span1,
.uneditable-input.span1 {
  width: 46px;
}
.controls-row {
  *zoom: 1;
}
.controls-row:before,
.controls-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.controls-row:after {
  clear: both;
}
.controls-row [class*="span"],
.row-fluid .controls-row [class*="span"] {
  float: left;
}
.controls-row .checkbox[class*="span"],
.controls-row .radio[class*="span"] {
  padding-top: 5px;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #eee;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
  background-color: transparent;
}
.control-group.warning .control-label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
  color: #c09853;
}
.control-group.warning .checkbox,
.control-group.warning .radio,
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  color: #c09853;
}
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
  border-color: #c09853;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
.control-group.error .control-label,
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #EA526F;
}
.control-group.error .checkbox,
.control-group.error .radio,
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  color: #EA526F;
}
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
  border-color: #EA526F;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
  border-color: #e42549;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f5adbb;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f5adbb;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f5adbb;
}
.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
  color: #EA526F;
  background-color: #f2dede;
  border-color: #EA526F;
}
.control-group.success .control-label,
.control-group.success .help-block,
.control-group.success .help-inline {
  color: #468847;
}
.control-group.success .checkbox,
.control-group.success .radio,
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  color: #468847;
}
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
  border-color: #468847;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
  border-color: #356635;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
.control-group.info .control-label,
.control-group.info .help-block,
.control-group.info .help-inline {
  color: #3a87ad;
}
.control-group.info .checkbox,
.control-group.info .radio,
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  color: #3a87ad;
}
.control-group.info input,
.control-group.info select,
.control-group.info textarea {
  border-color: #3a87ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.control-group.info input:focus,
.control-group.info select:focus,
.control-group.info textarea:focus {
  border-color: #2d6987;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}
.control-group.info .input-prepend .add-on,
.control-group.info .input-append .add-on {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #3a87ad;
}
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
  color: #b94a48;
  border-color: #ee5f5b;
}
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}
.form-actions {
  padding: 19px 20px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}
.form-actions:before,
.form-actions:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-actions:after {
  clear: both;
}
.help-block,
.help-inline {
  color: #595959;
}
.help-block {
  display: block;
  margin-bottom: 10px;
}
.help-inline {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  vertical-align: middle;
  padding-left: 5px;
}
.input-append,
.input-prepend {
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input,
.input-append .dropdown-menu,
.input-prepend .dropdown-menu,
.input-append .popover,
.input-prepend .popover {
  font-size: 14px;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  vertical-align: top;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,
.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 20px;
  min-width: 16px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  background-color: #eee;
  border: 1px solid #ccc;
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  margin-left: -1px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}
input.search-query {
  padding-right: 14px;
  padding-right: 4px \9;
  padding-left: 14px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
/* Allow for input prepend/append in search forms */
.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.form-search .input-append .search-query {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search .input-append .btn {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .search-query {
  -webkit-border-radius: 0 14px 14px 0;
  -moz-border-radius: 0 14px 14px 0;
  border-radius: 0 14px 14px 0;
}
.form-search .input-prepend .btn {
  -webkit-border-radius: 14px 0 0 14px;
  -moz-border-radius: 14px 0 0 14px;
  border-radius: 14px 0 0 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
  display: none;
}
.form-search label,
.form-inline label,
.form-search .btn-group,
.form-inline .btn-group {
  display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
  margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
  padding-left: 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
  float: left;
  margin-right: 3px;
  margin-left: 0;
}
.control-group {
  margin-bottom: 10px;
}
legend + .control-group {
  margin-top: 20px;
  -webkit-margin-top-collapse: separate;
}
.form-horizontal .control-group {
  margin-bottom: 20px;
  *zoom: 1;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-horizontal .control-group:after {
  clear: both;
}
.form-horizontal .control-label {
  float: left;
  width: 160px;
  padding-top: 5px;
  text-align: right;
}
.form-horizontal .controls {
  *display: inline-block;
  *padding-left: 20px;
  margin-left: 180px;
  *margin-left: 0;
}
.form-horizontal .controls:first-child {
  *padding-left: 180px;
}
.form-horizontal .help-block {
  margin-bottom: 0;
}
.form-horizontal input + .help-block,
.form-horizontal select + .help-block,
.form-horizontal textarea + .help-block,
.form-horizontal .uneditable-input + .help-block,
.form-horizontal .input-prepend + .help-block,
.form-horizontal .input-append + .help-block {
  margin-top: 10px;
}
.form-horizontal .form-actions {
  padding-left: 180px;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.table {
  width: 100%;
  margin-bottom: 20px;
}
.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table th {
  font-weight: bold;
}
.table thead th {
  vertical-align: bottom;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.table-bordered {
  border: 1px solid #ddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.table-bordered th,
.table-bordered td {
  border-left: 1px solid #ddd;
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}
.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
}
.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
}
.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}
.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
}
.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
.table td.span1,
.table th.span1 {
  float: none;
  width: 44px;
  margin-left: 0;
}
.table td.span2,
.table th.span2 {
  float: none;
  width: 124px;
  margin-left: 0;
}
.table td.span3,
.table th.span3 {
  float: none;
  width: 204px;
  margin-left: 0;
}
.table td.span4,
.table th.span4 {
  float: none;
  width: 284px;
  margin-left: 0;
}
.table td.span5,
.table th.span5 {
  float: none;
  width: 364px;
  margin-left: 0;
}
.table td.span6,
.table th.span6 {
  float: none;
  width: 444px;
  margin-left: 0;
}
.table td.span7,
.table th.span7 {
  float: none;
  width: 524px;
  margin-left: 0;
}
.table td.span8,
.table th.span8 {
  float: none;
  width: 604px;
  margin-left: 0;
}
.table td.span9,
.table th.span9 {
  float: none;
  width: 684px;
  margin-left: 0;
}
.table td.span10,
.table th.span10 {
  float: none;
  width: 764px;
  margin-left: 0;
}
.table td.span11,
.table th.span11 {
  float: none;
  width: 844px;
  margin-left: 0;
}
.table td.span12,
.table th.span12 {
  float: none;
  width: 924px;
  margin-left: 0;
}
.table tbody tr.success > td {
  background-color: #dff0d8;
}
.table tbody tr.error > td {
  background-color: #f2dede;
}
.table tbody tr.warning > td {
  background-color: #fcf8e3;
}
.table tbody tr.info > td {
  background-color: #d9edf7;
}
.table-hover tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
.table-hover tbody tr.error:hover > td {
  background-color: #ebcccc;
}
.table-hover tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
.table-hover tbody tr.info:hover > td {
  background-color: #c4e3f3;
}
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  *margin-right: 0.3em;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("/common.assets/third_party/bootstrap/img/glyphicons-halflings.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
  margin-top: 1px;
}
/* White icons with optional class, or on hover/focus/active states of certain elements */
.icon-white,
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:focus > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > li > a:focus > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"],
.dropdown-submenu:hover > a > [class^="icon-"],
.dropdown-submenu:focus > a > [class^="icon-"],
.dropdown-submenu:hover > a > [class*=" icon-"],
.dropdown-submenu:focus > a > [class*=" icon-"] {
  background-image: url("/common.assets/third_party/bootstrap/img/glyphicons-halflings-white.png");
}
.icon-glass {
  background-position: 0      0;
}
.icon-music {
  background-position: -24px 0;
}
.icon-search {
  background-position: -48px 0;
}
.icon-envelope {
  background-position: -72px 0;
}
.icon-heart {
  background-position: -96px 0;
}
.icon-star {
  background-position: -120px 0;
}
.icon-star-empty {
  background-position: -144px 0;
}
.icon-user {
  background-position: -168px 0;
}
.icon-film {
  background-position: -192px 0;
}
.icon-th-large {
  background-position: -216px 0;
}
.icon-th {
  background-position: -240px 0;
}
.icon-th-list {
  background-position: -264px 0;
}
.icon-ok {
  background-position: -288px 0;
}
.icon-remove {
  background-position: -312px 0;
}
.icon-zoom-in {
  background-position: -336px 0;
}
.icon-zoom-out {
  background-position: -360px 0;
}
.icon-off {
  background-position: -384px 0;
}
.icon-signal {
  background-position: -408px 0;
}
.icon-cog {
  background-position: -432px 0;
}
.icon-trash {
  background-position: -456px 0;
}
.icon-home {
  background-position: 0 -24px;
}
.icon-file {
  background-position: -24px -24px;
}
.icon-time {
  background-position: -48px -24px;
}
.icon-road {
  background-position: -72px -24px;
}
.icon-download-alt {
  background-position: -96px -24px;
}
.icon-download {
  background-position: -120px -24px;
}
.icon-upload {
  background-position: -144px -24px;
}
.icon-inbox {
  background-position: -168px -24px;
}
.icon-play-circle {
  background-position: -192px -24px;
}
.icon-repeat {
  background-position: -216px -24px;
}
.icon-refresh {
  background-position: -240px -24px;
}
.icon-list-alt {
  background-position: -264px -24px;
}
.icon-lock {
  background-position: -287px -24px;
}
.icon-flag {
  background-position: -312px -24px;
}
.icon-headphones {
  background-position: -336px -24px;
}
.icon-volume-off {
  background-position: -360px -24px;
}
.icon-volume-down {
  background-position: -384px -24px;
}
.icon-volume-up {
  background-position: -408px -24px;
}
.icon-qrcode {
  background-position: -432px -24px;
}
.icon-barcode {
  background-position: -456px -24px;
}
.icon-tag {
  background-position: 0 -48px;
}
.icon-tags {
  background-position: -25px -48px;
}
.icon-book {
  background-position: -48px -48px;
}
.icon-bookmark {
  background-position: -72px -48px;
}
.icon-print {
  background-position: -96px -48px;
}
.icon-camera {
  background-position: -120px -48px;
}
.icon-font {
  background-position: -144px -48px;
}
.icon-bold {
  background-position: -167px -48px;
}
.icon-italic {
  background-position: -192px -48px;
}
.icon-text-height {
  background-position: -216px -48px;
}
.icon-text-width {
  background-position: -240px -48px;
}
.icon-align-left {
  background-position: -264px -48px;
}
.icon-align-center {
  background-position: -288px -48px;
}
.icon-align-right {
  background-position: -312px -48px;
}
.icon-align-justify {
  background-position: -336px -48px;
}
.icon-list {
  background-position: -360px -48px;
}
.icon-indent-left {
  background-position: -384px -48px;
}
.icon-indent-right {
  background-position: -408px -48px;
}
.icon-facetime-video {
  background-position: -432px -48px;
}
.icon-picture {
  background-position: -456px -48px;
}
.icon-pencil {
  background-position: 0 -72px;
}
.icon-map-marker {
  background-position: -24px -72px;
}
.icon-adjust {
  background-position: -48px -72px;
}
.icon-tint {
  background-position: -72px -72px;
}
.icon-edit {
  background-position: -96px -72px;
}
.icon-share {
  background-position: -120px -72px;
}
.icon-check {
  background-position: -144px -72px;
}
.icon-move {
  background-position: -168px -72px;
}
.icon-step-backward {
  background-position: -192px -72px;
}
.icon-fast-backward {
  background-position: -216px -72px;
}
.icon-backward {
  background-position: -240px -72px;
}
.icon-play {
  background-position: -264px -72px;
}
.icon-pause {
  background-position: -288px -72px;
}
.icon-stop {
  background-position: -312px -72px;
}
.icon-forward {
  background-position: -336px -72px;
}
.icon-fast-forward {
  background-position: -360px -72px;
}
.icon-step-forward {
  background-position: -384px -72px;
}
.icon-eject {
  background-position: -408px -72px;
}
.icon-chevron-left {
  background-position: -432px -72px;
}
.icon-chevron-right {
  background-position: -456px -72px;
}
.icon-plus-sign {
  background-position: 0 -96px;
}
.icon-minus-sign {
  background-position: -24px -96px;
}
.icon-remove-sign {
  background-position: -48px -96px;
}
.icon-ok-sign {
  background-position: -72px -96px;
}
.icon-question-sign {
  background-position: -96px -96px;
}
.icon-info-sign {
  background-position: -120px -96px;
}
.icon-screenshot {
  background-position: -144px -96px;
}
.icon-remove-circle {
  background-position: -168px -96px;
}
.icon-ok-circle {
  background-position: -192px -96px;
}
.icon-ban-circle {
  background-position: -216px -96px;
}
.icon-arrow-left {
  background-position: -240px -96px;
}
.icon-arrow-right {
  background-position: -264px -96px;
}
.icon-arrow-up {
  background-position: -289px -96px;
}
.icon-arrow-down {
  background-position: -312px -96px;
}
.icon-share-alt {
  background-position: -336px -96px;
}
.icon-resize-full {
  background-position: -360px -96px;
}
.icon-resize-small {
  background-position: -384px -96px;
}
.icon-plus {
  background-position: -408px -96px;
}
.icon-minus {
  background-position: -433px -96px;
}
.icon-asterisk {
  background-position: -456px -96px;
}
.icon-exclamation-sign {
  background-position: 0 -120px;
}
.icon-gift {
  background-position: -24px -120px;
}
.icon-leaf {
  background-position: -48px -120px;
}
.icon-fire {
  background-position: -72px -120px;
}
.icon-eye-open {
  background-position: -96px -120px;
}
.icon-eye-close {
  background-position: -120px -120px;
}
.icon-warning-sign {
  background-position: -144px -120px;
}
.icon-plane {
  background-position: -168px -120px;
}
.icon-calendar {
  background-position: -192px -120px;
}
.icon-random {
  background-position: -216px -120px;
  width: 16px;
}
.icon-comment {
  background-position: -240px -120px;
}
.icon-magnet {
  background-position: -264px -120px;
}
.icon-chevron-up {
  background-position: -288px -120px;
}
.icon-chevron-down {
  background-position: -313px -119px;
}
.icon-retweet {
  background-position: -336px -120px;
}
.icon-shopping-cart {
  background-position: -360px -120px;
}
.icon-folder-close {
  background-position: -384px -120px;
  width: 16px;
}
.icon-folder-open {
  background-position: -408px -120px;
  width: 16px;
}
.icon-resize-vertical {
  background-position: -432px -119px;
}
.icon-resize-horizontal {
  background-position: -456px -118px;
}
.icon-hdd {
  background-position: 0 -144px;
}
.icon-bullhorn {
  background-position: -24px -144px;
}
.icon-bell {
  background-position: -48px -144px;
}
.icon-certificate {
  background-position: -72px -144px;
}
.icon-thumbs-up {
  background-position: -96px -144px;
}
.icon-thumbs-down {
  background-position: -120px -144px;
}
.icon-hand-right {
  background-position: -144px -144px;
}
.icon-hand-left {
  background-position: -168px -144px;
}
.icon-hand-up {
  background-position: -192px -144px;
}
.icon-hand-down {
  background-position: -216px -144px;
}
.icon-circle-arrow-right {
  background-position: -240px -144px;
}
.icon-circle-arrow-left {
  background-position: -264px -144px;
}
.icon-circle-arrow-up {
  background-position: -288px -144px;
}
.icon-circle-arrow-down {
  background-position: -312px -144px;
}
.icon-globe {
  background-position: -336px -144px;
}
.icon-wrench {
  background-position: -360px -144px;
}
.icon-tasks {
  background-position: -384px -144px;
}
.icon-filter {
  background-position: -408px -144px;
}
.icon-briefcase {
  background-position: -432px -144px;
}
.icon-fullscreen {
  background-position: -456px -144px;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  text-decoration: none;
  color: #fff;
  background-color: #2853ff;
  background-image: -moz-linear-gradient(top, #325bff, #1847ff);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#325bff), to(#1847ff));
  background-image: -webkit-linear-gradient(top, #325bff, #1847ff);
  background-image: -o-linear-gradient(top, #325bff, #1847ff);
  background-image: linear-gradient(to bottom, #325bff, #1847ff);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff325bff', endColorstr='#ff1847ff', GradientType=0);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #2853ff;
  background-image: -moz-linear-gradient(top, #325bff, #1847ff);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#325bff), to(#1847ff));
  background-image: -webkit-linear-gradient(top, #325bff, #1847ff);
  background-image: -o-linear-gradient(top, #325bff, #1847ff);
  background-image: linear-gradient(to bottom, #325bff, #1847ff);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff325bff', endColorstr='#ff1847ff', GradientType=0);
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #999;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}
.open {
  *z-index: 1000;
}
.open > .dropdown-menu {
  display: block;
}
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  z-index: 1051;
  margin-top: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
.well-large {
  padding: 24px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.well-small {
  padding: 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
.collapse.in {
  height: auto;
}
.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.btn {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  background-image: -o-linear-gradient(top, #fff, #e6e6e6);
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e6e6e6;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  border: 1px solid #ccc;
  *border: 0;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  *margin-left: 0.3em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}
.btn:active,
.btn.active {
  background-color: #cccccc \9;
}
.btn:first-child {
  *margin-left: 0;
}
.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-large [class^="icon-"],
.btn-large [class*=" icon-"] {
  margin-top: 4px;
}
.btn-small {
  padding: 2px 10px;
  font-size: 11.9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-small [class^="icon-"],
.btn-small [class*=" icon-"] {
  margin-top: 0;
}
.btn-mini [class^="icon-"],
.btn-mini [class*=" icon-"] {
  margin-top: -1px;
}
.btn-mini {
  padding: 0 6px;
  font-size: 10.5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #1e4bff;
  background-image: -moz-linear-gradient(top, #325bff, #0033fe);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#325bff), to(#0033fe));
  background-image: -webkit-linear-gradient(top, #325bff, #0033fe);
  background-image: -o-linear-gradient(top, #325bff, #0033fe);
  background-image: linear-gradient(to bottom, #325bff, #0033fe);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff325bff', endColorstr='#ff0033fe', GradientType=0);
  border-color: #0033fe #0033fe #0023b2;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #0033fe;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #fff;
  background-color: #0033fe;
  *background-color: #002ee5;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #0029cb \9;
}
.btn-warning {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #f89406;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #fff;
  background-color: #f89406;
  *background-color: #df8505;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}
.btn-danger {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #e84060;
  background-image: -moz-linear-gradient(top, #EA526F, #e42549);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#EA526F), to(#e42549));
  background-image: -webkit-linear-gradient(top, #EA526F, #e42549);
  background-image: -o-linear-gradient(top, #EA526F, #e42549);
  background-image: linear-gradient(to bottom, #EA526F, #e42549);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffea526f', endColorstr='#ffe42549', GradientType=0);
  border-color: #e42549 #e42549 #a81431;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e42549;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #fff;
  background-color: #e42549;
  *background-color: #d61a3e;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #bf1737 \9;
}
.btn-success {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-image: linear-gradient(to bottom, #62c462, #51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #51a351;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #fff;
  background-color: #51a351;
  *background-color: #499249;
}
.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}
.btn-info {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f96b4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #fff;
  background-color: #2f96b4;
  *background-color: #2a85a0;
}
.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}
.btn-inverse {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  background-image: -moz-linear-gradient(top, #444, #222);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#444), to(#222));
  background-image: -webkit-linear-gradient(top, #444, #222);
  background-image: -o-linear-gradient(top, #444, #222);
  background-image: linear-gradient(to bottom, #444, #222);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff444444', endColorstr='#ff222222', GradientType=0);
  border-color: #222 #222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #222;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #fff;
  background-color: #222;
  *background-color: #151515;
}
.btn-inverse:active,
.btn-inverse.active {
  background-color: #080808 \9;
}
button.btn,
input[type="submit"].btn {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
  *padding-top: 7px;
  *padding-bottom: 7px;
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
  *padding-top: 3px;
  *padding-bottom: 3px;
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
  *padding-top: 1px;
  *padding-bottom: 1px;
}
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #325bff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-link:hover,
.btn-link:focus {
  color: #002ee4;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: #333;
  text-decoration: none;
}
.btn-group {
  position: relative;
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;
  *margin-left: 0.3em;
}
.btn-group:first-child {
  *margin-left: 0;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-toolbar > .btn + .btn,
.btn-toolbar > .btn-group + .btn,
.btn-toolbar > .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group > .btn + .btn {
  margin-left: -1px;
}
.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
  font-size: 14px;
}
.btn-group > .btn-mini {
  font-size: 10.5px;
}
.btn-group > .btn-small {
  font-size: 11.9px;
}
.btn-group > .btn-large {
  font-size: 17.5px;
}
.btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}
.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  *padding-top: 5px;
  *padding-bottom: 5px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
  *padding-top: 2px;
  *padding-bottom: 2px;
}
.btn-group > .btn-small + .dropdown-toggle {
  *padding-top: 5px;
  *padding-bottom: 4px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
  *padding-top: 7px;
  *padding-bottom: 7px;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #0033fe;
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #f89406;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #e42549;
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: #51a351;
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: #2f96b4;
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #222;
}
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.btn-mini .caret,
.btn-small .caret {
  margin-top: 8px;
}
.dropup .btn-large .caret {
  border-bottom-width: 5px;
}
.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.btn-group-vertical {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.btn-group-vertical > .btn:last-child {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}
.btn-group-vertical > .btn-large:first-child {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.btn-group-vertical > .btn-large:last-child {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.alert,
.alert h4 {
  color: #c09853;
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
.alert-success h4 {
  color: #468847;
}
.alert-danger,
.alert-error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #EA526F;
}
.alert-danger h4,
.alert-error h4 {
  color: #EA526F;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.alert-info h4 {
  color: #3a87ad;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
.nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.nav > li > a {
  display: block;
}
.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}
.nav > li > a > img {
  max-width: none;
}
.nav > .pull-right {
  float: right;
}
.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}
.nav li + .nav-header {
  margin-top: 9px;
}
.nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.nav-list > li > a {
  padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #325bff;
}
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}
.nav-tabs,
.nav-pills {
  *zoom: 1;
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}
.nav-tabs:after,
.nav-pills:after {
  clear: both;
}
.nav-tabs > li,
.nav-pills > li {
  float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: #eee #eee #ddd;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}
.nav-pills > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
  color: #fff;
  background-color: #325bff;
}
.nav-stacked > li {
  float: none;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-tabs.nav-stacked {
  border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
  border: 1px solid #ddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
.nav-tabs.nav-stacked > li:last-child > a {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus {
  border-color: #ddd;
  z-index: 2;
}
.nav-pills.nav-stacked > li > a {
  margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
  margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.nav-pills .dropdown-menu {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.nav .dropdown-toggle .caret {
  border-top-color: #325bff;
  border-bottom-color: #325bff;
  margin-top: 6px;
}
.nav .dropdown-toggle:hover .caret,
.nav .dropdown-toggle:focus .caret {
  border-top-color: #002ee4;
  border-bottom-color: #002ee4;
}
/* move down carets for tabs */
.nav-tabs .dropdown-toggle .caret {
  margin-top: 8px;
}
.nav .active .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.nav-tabs .active .dropdown-toggle .caret {
  border-top-color: #555;
  border-bottom-color: #555;
}
.nav > .dropdown.active > a:hover,
.nav > .dropdown.active > a:focus {
  cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
  color: #fff;
  background-color: #999;
  border-color: #999;
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret,
.nav li.dropdown.open a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tabs-stacked .open > a:hover,
.tabs-stacked .open > a:focus {
  border-color: #999;
}
.tabbable {
  *zoom: 1;
}
.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}
.tabbable:after {
  clear: both;
}
.tab-content {
  overflow: auto;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eee #ddd #eee #eee;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #fff;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eee #eee #eee #ddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #fff;
}
.nav > .disabled > a {
  color: #999;
}
.nav > .disabled > a:hover,
.nav > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.navbar {
  overflow: visible;
  margin-bottom: 20px;
  *position: relative;
  *z-index: 2;
}
.navbar-inner {
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fafafa;
  background-image: -moz-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f2f2f2));
  background-image: -webkit-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: -o-linear-gradient(top, #ffffff, #f2f2f2);
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#fff2f2f2', GradientType=0);
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  *zoom: 1;
}
.navbar-inner:before,
.navbar-inner:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-inner:after {
  clear: both;
}
.navbar .container {
  width: auto;
}
.nav-collapse.collapse {
  height: auto;
  overflow: visible;
}
.navbar .brand {
  float: left;
  display: block;
  padding: 10px 20px 10px;
  margin-left: -20px;
  font-size: 20px;
  font-weight: 200;
  color: #777;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .brand:hover,
.navbar .brand:focus {
  text-decoration: none;
}
.navbar-text {
  margin-bottom: 0;
  line-height: 40px;
  color: #777;
}
.navbar-link {
  color: #777;
}
.navbar-link:hover,
.navbar-link:focus {
  color: #333;
}
.navbar .divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #ffffff;
}
.navbar .btn,
.navbar .btn-group {
  margin-top: 5px;
}
.navbar .btn-group .btn,
.navbar .input-prepend .btn,
.navbar .input-append .btn,
.navbar .input-prepend .btn-group,
.navbar .input-append .btn-group {
  margin-top: 0;
}
.navbar-form {
  margin-bottom: 0;
  *zoom: 1;
}
.navbar-form:before,
.navbar-form:after {
  display: table;
  content: "";
  line-height: 0;
}
.navbar-form:after {
  clear: both;
}
.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
  margin-top: 5px;
}
.navbar-form input,
.navbar-form select,
.navbar-form .btn {
  display: inline-block;
  margin-bottom: 0;
}
.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
  margin-top: 3px;
}
.navbar-form .input-append,
.navbar-form .input-prepend {
  margin-top: 5px;
  white-space: nowrap;
}
.navbar-form .input-append input,
.navbar-form .input-prepend input {
  margin-top: 0;
}
.navbar-search {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-bottom: 0;
}
.navbar-search .search-query {
  margin-bottom: 0;
  padding: 4px 14px;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.navbar-static-top {
  position: static;
  margin-bottom: 0;
}
.navbar-static-top .navbar-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-bottom: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  border-width: 0 0 1px;
}
.navbar-fixed-bottom .navbar-inner {
  border-width: 1px 0 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
  padding-left: 0;
  padding-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar-static-top .container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
  width: 940px;
}
.navbar-fixed-top {
  top: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-static-top .navbar-inner {
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
.navbar-fixed-bottom {
  bottom: 0;
}
.navbar-fixed-bottom .navbar-inner {
  -webkit-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  -moz-box-shadow: 0 -1px 10px rgba(0,0,0,.1);
  box-shadow: 0 -1px 10px rgba(0,0,0,.1);
}
.navbar .nav {
  position: relative;
  left: 0;
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.navbar .nav.pull-right {
  float: right;
  margin-right: 0;
}
.navbar .nav > li {
  float: left;
}
.navbar .nav > li > a {
  float: none;
  padding: 10px 15px 10px;
  color: #777;
  text-decoration: none;
  text-shadow: 0 1px 0 #ffffff;
}
.navbar .nav .dropdown-toggle .caret {
  margin-top: 8px;
}
.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  background-color: transparent;
  color: #333;
  text-decoration: none;
}
.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  color: #555;
  text-decoration: none;
  background-color: #e5e5e5;
  -webkit-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.125);
}
.navbar .btn-navbar {
  display: none;
  float: right;
  padding: 7px 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ededed;
  background-image: -moz-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#e5e5e5));
  background-image: -webkit-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: -o-linear-gradient(top, #f2f2f2, #e5e5e5);
  background-image: linear-gradient(to bottom, #f2f2f2, #e5e5e5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffe5e5e5', GradientType=0);
  border-color: #e5e5e5 #e5e5e5 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #e5e5e5;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.navbar .btn-navbar:hover,
.navbar .btn-navbar:focus,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
  color: #fff;
  background-color: #e5e5e5;
  *background-color: #d9d9d9;
}
.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
  background-color: #cccccc \9;
}
.navbar .btn-navbar .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  background-color: #f5f5f5;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-navbar .icon-bar + .icon-bar {
  margin-top: 3px;
}
.navbar .nav > li > .dropdown-menu:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 9px;
}
.navbar .nav > li > .dropdown-menu:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  top: -6px;
  left: 10px;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:before {
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  bottom: -7px;
  top: auto;
}
.navbar-fixed-bottom .nav > li > .dropdown-menu:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
  top: auto;
}
.navbar .nav li.dropdown > a:hover .caret,
.navbar .nav li.dropdown > a:focus .caret {
  border-top-color: #333;
  border-bottom-color: #333;
}
.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #e5e5e5;
  color: #555;
}
.navbar .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #777;
  border-bottom-color: #777;
}
.navbar .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #555;
  border-bottom-color: #555;
}
.navbar .pull-right > li > .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}
.navbar .pull-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.pull-right:before {
  left: auto;
  right: 12px;
}
.navbar .pull-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.pull-right:after {
  left: auto;
  right: 13px;
}
.navbar .pull-right > li > .dropdown-menu .dropdown-menu,
.navbar .nav > li > .dropdown-menu.pull-right .dropdown-menu {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: -1px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.navbar-inverse .navbar-inner {
  background-color: #1b1b1b;
  background-image: -moz-linear-gradient(top, #222222, #111111);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#222222), to(#111111));
  background-image: -webkit-linear-gradient(top, #222222, #111111);
  background-image: -o-linear-gradient(top, #222222, #111111);
  background-image: linear-gradient(to bottom, #222222, #111111);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff222222', endColorstr='#ff111111', GradientType=0);
  border-color: #252525;
}
.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #999;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-inverse .brand:hover,
.navbar-inverse .nav > li > a:hover,
.navbar-inverse .brand:focus,
.navbar-inverse .nav > li > a:focus {
  color: #fff;
}
.navbar-inverse .brand {
  color: #999;
}
.navbar-inverse .navbar-text {
  color: #999;
}
.navbar-inverse .nav > li > a:focus,
.navbar-inverse .nav > li > a:hover {
  background-color: transparent;
  color: #fff;
}
.navbar-inverse .nav .active > a,
.navbar-inverse .nav .active > a:hover,
.navbar-inverse .nav .active > a:focus {
  color: #fff;
  background-color: #111111;
}
.navbar-inverse .navbar-link {
  color: #999;
}
.navbar-inverse .navbar-link:hover,
.navbar-inverse .navbar-link:focus {
  color: #fff;
}
.navbar-inverse .divider-vertical {
  border-left-color: #111111;
  border-right-color: #222222;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: #111111;
  color: #fff;
}
.navbar-inverse .nav li.dropdown > a:hover .caret,
.navbar-inverse .nav li.dropdown > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navbar-inverse .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #999;
  border-bottom-color: #999;
}
.navbar-inverse .nav li.dropdown.open > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.active > .dropdown-toggle .caret,
.navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navbar-inverse .navbar-search .search-query {
  color: #fff;
  background-color: #515151;
  border-color: #111111;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.navbar-inverse .navbar-search .search-query:-moz-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query:-ms-input-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query::-webkit-input-placeholder {
  color: #ccc;
}
.navbar-inverse .navbar-search .search-query:focus,
.navbar-inverse .navbar-search .search-query.focused {
  padding: 5px 15px;
  color: #333;
  text-shadow: 0 1px 0 #fff;
  background-color: #fff;
  border: 0;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  outline: 0;
}
.navbar-inverse .btn-navbar {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e0e0e;
  background-image: -moz-linear-gradient(top, #151515, #040404);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#151515), to(#040404));
  background-image: -webkit-linear-gradient(top, #151515, #040404);
  background-image: -o-linear-gradient(top, #151515, #040404);
  background-image: linear-gradient(to bottom, #151515, #040404);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff151515', endColorstr='#ff040404', GradientType=0);
  border-color: #040404 #040404 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #040404;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.navbar-inverse .btn-navbar:hover,
.navbar-inverse .btn-navbar:focus,
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active,
.navbar-inverse .btn-navbar.disabled,
.navbar-inverse .btn-navbar[disabled] {
  color: #fff;
  background-color: #040404;
  *background-color: #000000;
}
.navbar-inverse .btn-navbar:active,
.navbar-inverse .btn-navbar.active {
  background-color: #000000 \9;
}
.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 20px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.breadcrumb > li {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  text-shadow: 0 1px 0 #fff;
}
.breadcrumb > li > .divider {
  padding: 0 5px;
  color: #ccc;
}
.breadcrumb > .active {
  color: #999;
}
.pagination {
  margin: 20px 0;
}
.pagination ul {
  display: inline-block;
  *display: inline;
  /* IE7 inline-block hack */
  *zoom: 1;
  margin-left: 0;
  margin-bottom: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination ul > li {
  display: inline;
}
.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  padding: 4px 12px;
  line-height: 20px;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left-width: 0;
}
.pagination ul > li > a:hover,
.pagination ul > li > a:focus,
.pagination ul > .active > a,
.pagination ul > .active > span {
  background-color: #f5f5f5;
}
.pagination ul > .active > a,
.pagination ul > .active > span {
  color: #999;
  cursor: default;
}
.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover,
.pagination ul > .disabled > a:focus {
  color: #999;
  background-color: transparent;
  cursor: default;
}
.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-left-width: 1px;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}
.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}
.pagination-large ul > li > a,
.pagination-large ul > li > span {
  padding: 11px 19px;
  font-size: 17.5px;
}
.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span {
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span {
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  border-bottom-right-radius: 3px;
}
.pagination-small ul > li > a,
.pagination-small ul > li > span {
  padding: 2px 10px;
  font-size: 11.9px;
}
.pagination-mini ul > li > a,
.pagination-mini ul > li > span {
  padding: 0 6px;
  font-size: 10.5px;
}
.pager {
  margin: 20px 0;
  list-style: none;
  text-align: center;
  *zoom: 1;
}
.pager:before,
.pager:after {
  display: table;
  content: "";
  line-height: 0;
}
.pager:after {
  clear: both;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #f5f5f5;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #999;
  background-color: #fff;
  cursor: default;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 1050;
  width: 560px;
  margin-left: -280px;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.3);
  *border: 1px solid #999;
  /* IE6-7 */
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}
.modal.fade {
  -webkit-transition: opacity .3s linear, top .3s ease-out;
  -moz-transition: opacity .3s linear, top .3s ease-out;
  -o-transition: opacity .3s linear, top .3s ease-out;
  transition: opacity .3s linear, top .3s ease-out;
  top: -25%;
}
.modal.fade.in {
  top: 10%;
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
}
.modal-header .close {
  margin-top: 2px;
}
.modal-header h3 {
  margin: 0;
  line-height: 30px;
}
.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
.modal-form {
  margin-bottom: 0;
}
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: inset 0 1px 0 #fff;
  -moz-box-shadow: inset 0 1px 0 #fff;
  box-shadow: inset 0 1px 0 #fff;
  *zoom: 1;
}
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: "";
  line-height: 0;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  font-size: 11px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal;
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}
.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.popover-title:empty {
  display: none;
}
.popover-content {
  padding: 9px 14px;
}
.popover .arrow,
.popover .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow {
  border-width: 11px;
}
.popover .arrow:after {
  border-width: 10px;
  content: "";
}
.popover.top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}
.popover.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.popover.right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.right .arrow:after {
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
}
.popover.bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}
.popover.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.popover.left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left .arrow:after {
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px;
}
.thumbnails {
  margin-left: -20px;
  list-style: none;
  *zoom: 1;
}
.thumbnails:before,
.thumbnails:after {
  display: table;
  content: "";
  line-height: 0;
}
.thumbnails:after {
  clear: both;
}
.row-fluid .thumbnails {
  margin-left: 0;
}
.thumbnails > li {
  float: left;
  margin-bottom: 20px;
  margin-left: 20px;
}
.thumbnail {
  display: block;
  padding: 4px;
  line-height: 20px;
  border: 1px solid #ddd;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a.thumbnail:hover,
a.thumbnail:focus {
  border-color: #325bff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
  box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.thumbnail .caption {
  padding: 9px;
  color: #555;
}
.media,
.media-body {
  overflow: hidden;
  *overflow: visible;
  zoom: 1;
}
.media,
.media .media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}
.media-object {
  display: block;
}
.media-heading {
  margin: 0 0 5px;
}
.media > .pull-left {
  margin-right: 10px;
}
.media > .pull-right {
  margin-left: 10px;
}
.media-list {
  margin-left: 0;
  list-style: none;
}
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: bold;
  line-height: 14px;
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #999;
}
.label {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.label:empty,
.badge:empty {
  display: none;
}
a.label:hover,
a.label:focus,
a.badge:hover,
a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.label-important,
.badge-important {
  background-color: #EA526F;
}
.label-important[href],
.badge-important[href] {
  background-color: #e42549;
}
.label-warning,
.badge-warning {
  background-color: #f89406;
}
.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
.label-success,
.badge-success {
  background-color: #468847;
}
.label-success[href],
.badge-success[href] {
  background-color: #356635;
}
.label-info,
.badge-info {
  background-color: #3a87ad;
}
.label-info[href],
.badge-info[href] {
  background-color: #2d6987;
}
.label-inverse,
.badge-inverse {
  background-color: #333;
}
.label-inverse[href],
.badge-inverse[href] {
  background-color: #1a1a1a;
}
.btn .label,
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-mini .label,
.btn-mini .badge {
  top: 0;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.progress .bar {
  width: 0%;
  height: 100%;
  color: #fff;
  float: left;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #0e90d2;
  background-image: -moz-linear-gradient(top, #149bdf, #0480be);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
  background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
  background-image: -o-linear-gradient(top, #149bdf, #0480be);
  background-image: linear-gradient(to bottom, #149bdf, #0480be);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf', endColorstr='#ff0480be', GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress .bar + .bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 1px 0 0 rgba(0,0,0,.15), inset 0 -1px 0 rgba(0,0,0,.15);
}
.progress-striped .bar {
  background-color: #149bdf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-danger .bar,
.progress .bar-danger {
  background-color: #dd514c;
  background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
  background-image: linear-gradient(to bottom, #ee5f5b, #c43c35);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffc43c35', GradientType=0);
}
.progress-danger.progress-striped .bar,
.progress-striped .bar-danger {
  background-color: #ee5f5b;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-success .bar,
.progress .bar-success {
  background-color: #5eb95e;
  background-image: -moz-linear-gradient(top, #62c462, #57a957);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
  background-image: -webkit-linear-gradient(top, #62c462, #57a957);
  background-image: -o-linear-gradient(top, #62c462, #57a957);
  background-image: linear-gradient(to bottom, #62c462, #57a957);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62c462', endColorstr='#ff57a957', GradientType=0);
}
.progress-success.progress-striped .bar,
.progress-striped .bar-success {
  background-color: #62c462;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-info .bar,
.progress .bar-info {
  background-color: #4bb1cf;
  background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
  background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
  background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
  background-image: linear-gradient(to bottom, #5bc0de, #339bb9);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff339bb9', GradientType=0);
}
.progress-info.progress-striped .bar,
.progress-striped .bar-info {
  background-color: #5bc0de;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-warning .bar,
.progress .bar-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(to bottom, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffbb450', endColorstr='#fff89406', GradientType=0);
}
.progress-warning.progress-striped .bar,
.progress-striped .bar-warning {
  background-color: #fbb450;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.accordion {
  margin-bottom: 20px;
}
.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.accordion-heading {
  border-bottom: 0;
}
.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px;
}
.accordion-toggle {
  cursor: pointer;
}
.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5;
}
.carousel {
  position: relative;
  margin-bottom: 20px;
  line-height: 1;
}
.carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  line-height: 1;
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #fff;
  text-align: center;
  background: #222;
  border: 3px solid #fff;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.carousel-control.right {
  left: auto;
  right: 15px;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-indicators {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  margin: 0;
  list-style: none;
}
.carousel-indicators li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  text-indent: -999px;
  background-color: #ccc;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
}
.carousel-indicators .active {
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #333;
  background: rgba(0, 0, 0, 0.75);
}
.carousel-caption h4,
.carousel-caption p {
  color: #fff;
  line-height: 20px;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}
.hero-unit {
  padding: 60px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  color: inherit;
  background-color: #eee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  color: inherit;
  letter-spacing: -1px;
}
.hero-unit li {
  line-height: 30px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}
.affix {
  position: fixed;
}
.submit-button {
  background-color: var(--todovu-primary-color);
  border: none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  fill: #ffffff;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: #ffffff;
  -webkit-tap-highlight-color: #0090bd;
  text-align: center;
  text-decoration: none;
}
.submit-button:active {
  background-color: #0090bd;
}
.submit-button[disabled] {
  background-color: #dddddd;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.submit-button[disabled]:hover {
  background-color: #dddddd;
}
.submit-button:hover,
.submit-button:focus {
  background-color: #0090bd;
  color: #ffffff;
  text-decoration: none;
}
.submit-button:not(:last-child) {
  margin-right: 10px;
}
.submit-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.submit-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.submit-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.submit-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.submit-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.submit-button.inline-block {
  display: inline-block;
}
.cancel-button {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  color: #cccccc;
  cursor: pointer;
  fill: #cccccc;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: #cccccc;
  -webkit-tap-highlight-color: #ffffff;
  text-align: center;
  text-decoration: none;
  box-shadow: inset 0px 0px 0px 1px #cccccc !important;
  /* a border that takes up no space */
}
.cancel-button:active {
  background-color: #ffffff;
}
.cancel-button[disabled] {
  background-color: transparent;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.cancel-button[disabled]:hover {
  background-color: transparent;
}
.cancel-button:hover,
.cancel-button:focus {
  background-color: #ffffff;
  color: #cccccc;
  text-decoration: none;
}
.cancel-button:not(:last-child) {
  margin-right: 10px;
}
.cancel-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.cancel-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.cancel-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.cancel-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.cancel-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.cancel-button.inline-block {
  display: inline-block;
}
.delete-button {
  background-color: red;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  fill: #ffffff;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: #ffffff;
  -webkit-tap-highlight-color: red;
  text-align: center;
  text-decoration: none;
}
.delete-button:active {
  background-color: red;
}
.delete-button[disabled] {
  background-color: #dddddd;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.delete-button[disabled]:hover {
  background-color: #dddddd;
}
.delete-button:hover,
.delete-button:focus {
  background-color: red;
  color: #ffffff;
  text-decoration: none;
}
.delete-button:not(:last-child) {
  margin-right: 10px;
}
.delete-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.delete-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.delete-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.delete-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.delete-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.delete-button.inline-block {
  display: inline-block;
}
.disconnect-button {
  background-color: var(--todovu-red);
  border: none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  fill: #ffffff;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: #ffffff;
  -webkit-tap-highlight-color: #b41011;
  text-align: center;
  text-decoration: none;
}
.disconnect-button:active {
  background-color: #b41011;
}
.disconnect-button[disabled] {
  background-color: #dddddd;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.disconnect-button[disabled]:hover {
  background-color: #dddddd;
}
.disconnect-button:hover,
.disconnect-button:focus {
  background-color: #b41011;
  color: #ffffff;
  text-decoration: none;
}
.disconnect-button:not(:last-child) {
  margin-right: 10px;
}
.disconnect-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.disconnect-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.disconnect-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.disconnect-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.disconnect-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.disconnect-button.inline-block {
  display: inline-block;
}
.help-button {
  background-color: #ababab;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  fill: white;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: white;
  -webkit-tap-highlight-color: #999999;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.help-button:active {
  background-color: #999999;
}
.help-button[disabled] {
  background-color: #ababab;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.help-button[disabled]:hover {
  background-color: #ababab;
}
.help-button:hover,
.help-button:focus {
  background-color: #999999;
  color: white;
  text-decoration: none;
}
.help-button:not(:last-child) {
  margin-right: 10px;
}
.help-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.help-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.help-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.help-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.help-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.help-button.inline-block {
  display: inline-block;
}
.navigate-button {
  background-color: #cccccc;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  fill: white;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: white;
  -webkit-tap-highlight-color: #cccccc;
  text-align: center;
  text-decoration: none;
}
.navigate-button:active {
  background-color: #cccccc;
}
.navigate-button[disabled] {
  background-color: #eeeeee;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.navigate-button[disabled]:hover {
  background-color: #eeeeee;
}
.navigate-button:hover,
.navigate-button:focus {
  background-color: #cccccc;
  color: white;
  text-decoration: none;
}
.navigate-button:not(:last-child) {
  margin-right: 10px;
}
.navigate-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.navigate-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.navigate-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.navigate-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.navigate-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.navigate-button.inline-block {
  display: inline-block;
}
.option-button {
  background-color: #cccccc;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  fill: #ffffff;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: #ffffff;
  -webkit-tap-highlight-color: #bbbbbb;
  text-align: center;
  text-decoration: none;
}
.option-button:active {
  background-color: #bbbbbb;
}
.option-button[disabled] {
  background-color: #cccccc;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.option-button[disabled]:hover {
  background-color: #cccccc;
}
.option-button:hover,
.option-button:focus {
  background-color: #bbbbbb;
  color: #ffffff;
  text-decoration: none;
}
.option-button:not(:last-child) {
  margin-right: 10px;
}
.option-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.option-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.option-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.option-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.option-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.option-button.inline-block {
  display: inline-block;
}
.option-button.narrow {
  padding-left: 12px;
  padding-right: 12px;
}
.option-button.enabled {
  background-color: #00b000;
}
.dismiss-button {
  background-color: #c4c4c4;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  fill: white;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: white;
  -webkit-tap-highlight-color: #ababab;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.dismiss-button:active {
  background-color: #ababab;
}
.dismiss-button[disabled] {
  background-color: #c4c4c4;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.dismiss-button[disabled]:hover {
  background-color: #c4c4c4;
}
.dismiss-button:hover,
.dismiss-button:focus {
  background-color: #ababab;
  color: white;
  text-decoration: none;
}
.dismiss-button:not(:last-child) {
  margin-right: 10px;
}
.dismiss-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.dismiss-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.dismiss-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.dismiss-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.dismiss-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.dismiss-button.inline-block {
  display: inline-block;
}
.upgrade-button {
  background-color: var(--todovu-primary-color);
  color: #ffffff;
  fill: #ffffff;
  stroke: #ffffff;
  -webkit-tap-highlight-color: #0090bd;
  background-color: #e95645;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  fill: white;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: white;
  -webkit-tap-highlight-color: #e95645;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  line-height: 20px;
  padding: 7px 26px 8px 29px;
}
.upgrade-button:active {
  background-color: #0090bd;
}
.upgrade-button[disabled] {
  background-color: #dddddd;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.upgrade-button[disabled]:hover {
  background-color: #dddddd;
}
.upgrade-button:hover,
.upgrade-button:focus {
  background-color: #0090bd;
  color: #ffffff;
  text-decoration: none;
}
.upgrade-button:not(:last-child) {
  margin-right: 10px;
}
.upgrade-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.upgrade-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.upgrade-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.upgrade-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.upgrade-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.upgrade-button.inline-block {
  display: inline-block;
}
.upgrade-button:active {
  background-color: #e95645;
}
.upgrade-button[disabled] {
  background-color: #e95645;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.upgrade-button[disabled]:hover {
  background-color: #e95645;
}
.upgrade-button:hover,
.upgrade-button:focus {
  background-color: #e95645;
  color: white;
  text-decoration: none;
}
.upgrade-button:not(:last-child) {
  margin-right: 10px;
}
.upgrade-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.upgrade-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.upgrade-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.upgrade-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.upgrade-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.upgrade-button.inline-block {
  display: inline-block;
}
:root {
  --todovu-blue-base: 50, 91, 255;
  --todovu-blue-base-50: 230, 235, 255;
  --todovu-blue-base-100: 194, 206, 255;
  --todovu-blue: rgb(var(--todovu-blue-base));
  --todovu-blue-50: rgb(var(--todovu-blue-base-50));
  --todovu-blue-100: rgb(var(--todovu-blue-base-100));
  --todovu-blue-200: #99adff;
  --todovu-blue-300: #708cff;
  --todovu-blue-400: #5174ff;
  --todovu-blue-500: #325bff;
  --todovu-blue-600: #2d53ff;
  --todovu-blue-700: #2649ff;
  --todovu-blue-800: #1f40ff;
  --todovu-blue-900: #132fff;
  --todovu-green-base: 44, 202, 115;
  --todovu-green-base-50: 230, 249, 238;
  --todovu-green-base-100: 192, 239, 213;
  --todovu-green: rgb(var(--todovu-green-base));
  --todovu-green-50: rgb(var(--todovu-green-base-50));
  --todovu-green-100: rgb(var(--todovu-green-base-100));
  --todovu-green-200: #96e5b9;
  --todovu-green-300: #6bda9d;
  --todovu-green-400: #4cd288;
  --todovu-green-500: #2cc872;
  --todovu-green-600: #27c56b;
  --todovu-green-700: #21bd60;
  --todovu-green-800: #1bb756;
  --todovu-green-900: #10ab43;
  --todovu-purple-base: 117, 80, 233;
  --todovu-purple-base-50: 238, 234, 252;
  --todovu-purple-base-100: 214, 203, 248;
  --todovu-purple: rgb(var(--todovu-purple-base));
  --todovu-purple-50: rgb(var(--todovu-purple-base-50));
  --todovu-purple-100: rgb(var(--todovu-purple-base-100));
  --todovu-purple-200: #baa8f4;
  --todovu-purple-300: #9e85f0;
  --todovu-purple-400: #8a6aec;
  --todovu-purple-500: #744de9;
  --todovu-purple-600: #6d49e6;
  --todovu-purple-700: #6240e3;
  --todovu-purple-800: #5837df;
  --todovu-purple-900: #4527d9;
  --todovu-red-base: 234, 82, 111;
  --todovu-red-base-50: 252, 234, 238;
  --todovu-red-base-100: 249, 204, 213;
  --todovu-red: rgb(var(--todovu-red-base));
  --todovu-red-50: rgb(var(--todovu-red-base-50));
  --todovu-red-100: rgb(var(--todovu-red-base-100));
  --todovu-red-200: #f5a9b7;
  --todovu-red-300: #f0869a;
  --todovu-red-400: #ed6c85;
  --todovu-red-500: #e9526e;
  --todovu-red-600: #e74b67;
  --todovu-red-700: #e4415c;
  --todovu-red-800: #e13852;
  --todovu-red-900: #db2840;
  --todovu-yellow: #FFD07B;
  --todovu-background-color: #f4f7fc;
  --todovu-selected-bg: rgba(0, 102, 255, 0.1);
  --sidebar-bgcolor: rgba(0, 0, 0, 0.04);
  --sidebar-bgcolor-selected: #f1f2ff;
  --sidebar-icon-color: rgba(0, 0, 0, 0.45);
  --sidebar-icon-color-selected: var(--todovu-primary-color);
  --sidebar-text-color: rgba(0, 0, 0, 0.54);
  --todovu-card-border-radius: 8px;
  --todovu-shadow-base: 115, 143, 179;
  --todovu-tile-border: 0px;
  --todovu-left-column-width: 300px;
  --todovu-elevation-1: 0px 2px 1px -1px rgba(var(--todovu-shadow-base), 0.2), 0px 1px 1px 0px rgba(var(--todovu-shadow-base),0.14), 0px 1px 3px 0px rgba(var(--todovu-shadow-base),0.12);
  --todovu-tile-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  --todovu-archived: #6a6a63;
  --todovu-archived-light: #E8E8D8;
}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
}
.display-inline-flex {
  display: inline-flex;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
.super-flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap {
  flex-wrap: wrap;
}
.super-flex-nowrap {
  flex-wrap: nowrap;
}
.horizontal-flex {
  display: flex;
  align-items: baseline;
}
.flex-justified {
  justify-content: space-between;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-whitespace {
  justify-content: space-around;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-center {
  align-items: center;
}
.flex-centered {
  justify-content: center;
  align-items: center;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-grow-zero {
  flex-grow: 0;
}
.flex-grow-one {
  flex-grow: 1;
}
.flex-shrink-zero {
  flex-shrink: 0;
}
.flex-shrink-one {
  flex-shrink: 1;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.input-required {
  color: var(--todovu-red);
  display: inline-block;
  font-size: 30px;
  height: 30px;
  vertical-align: sub;
}
.address-input {
  max-width: 400px;
  white-space: nowrap;
  width: 400px;
}
.address-input input {
  width: calc(100% - 20px);
}
.city-input,
.state-input,
.country-input,
.phone-input,
.fax-input,
.website-input,
.email-input {
  max-width: 200px;
  white-space: nowrap;
  width: 200px;
}
.city-input input,
.state-input input,
.country-input input,
.phone-input input,
.fax-input input,
.website-input input,
.email-input input {
  width: calc(100% - 20px);
}
.name-input {
  max-width: 400px;
  white-space: nowrap;
  width: 400px;
}
.name-input input {
  width: calc(46% - 10px);
}
.postcode-input {
  max-width: 100px;
  white-space: nowrap;
  width: 100px;
}
.postcode-input input {
  width: calc(100% - 20px);
}
.month-input,
.short-year-input {
  height: auto;
  width: 60px;
}
.creditcard-input + .cvv-input {
  margin-left: 10px;
}
.cvv-input {
  width: 40px;
}
.status-form-input[type=text] {
  background: #f5f5f5;
  border: 1px solid #c2c2c2;
  border-radius: 0px;
  margin: 0px;
}
.button-icon {
  fill: #ffffff;
  height: 16px;
  stroke: #ffffff;
  vertical-align: middle;
  width: 16px;
}
.menu-button-arrow-icon {
  fill: white;
  height: 16px;
  margin-left: 8px;
  stroke: white;
  vertical-align: -3px;
  width: 16px;
}
.menu-button-share-icon {
  fill: white;
  height: 16px;
  margin-right: 11px;
  stroke: white;
  vertical-align: text-top;
  width: 14px;
}
.menu-triangle-icon {
  fill: #b3b3b3;
  height: 8px;
  margin-left: 8px;
  stroke: #b3b3b3;
  vertical-align: 0px;
  width: 8px;
}
.portrait-upload {
  height: auto !important;
  line-height: normal !important;
}
.yesno-checkbox {
  height: 30px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.yesno-checkbox input[type=checkbox] {
  display: none;
}
.yesno-checkbox input[type=checkbox] ~ * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  line-height: 30px;
  text-align: center;
  width: 38px;
}
.yesno-checkbox input[type=checkbox] ~ .yes {
  background-color: #e8e8e8;
  border: 1px solid #cccccc;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  color: #999999;
  cursor: pointer;
}
.yesno-checkbox input[type=checkbox] ~ .no {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  color: white;
  cursor: default;
}
.yesno-checkbox input[type=checkbox]:checked ~ .yes {
  background-color: var(--todovu-primary-color);
  border: 1px solid var(--todovu-primary-color);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  color: white;
  cursor: default;
}
.yesno-checkbox input[type=checkbox]:checked ~ .no {
  background-color: #e8e8e8;
  border: 1px solid #cccccc;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  color: #999999;
  cursor: pointer;
}
.libraInactive {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1px;
  transition: border-bottom 0.3s;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.libraActive {
  border: none;
  border-bottom: 2px solid var(--todovu-primary-color);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
/*
In Firefox, empty active Libra inputs have zero height
http://stackoverflow.com/questions/23530231/contenteditable-true-height-issue-in-firefox
*/
.libraHint {
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: -7px;
  padding: 0px 7px;
}
.libraHint:hover {
  border-color: #bbbbbb #cccccc #cccccc #cccccc;
}
.libraActive.libraHint {
  border-color: #e0e0e0 #cccccc #cccccc #e0e0e0;
  box-shadow: inset 1px 1px 0px 0px #ebebeb;
  transition: none;
}
.portrait {
  flex-shrink: 0;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 50%;
}
.away {
  opacity: 0.5;
}
.portraitLarge {
  height: 75px;
  width: 75px;
}
.portraitNormal {
  margin-right: 9px;
  width: 48px;
  height: 48px;
}
.portrait36 {
  height: 36px;
  width: 36px;
}
.portraitMedium {
  height: 32px;
  width: 32px;
}
.portraitHalf {
  height: 24px;
  width: 24px;
}
.portraitSmall {
  height: 20px;
  width: 20px;
}
.portraitTiny {
  height: 16px;
  width: 16px;
}
.portrait17 {
  height: 17px;
  width: 17px;
}
.portraitSwitcher {
  height: 20px;
  width: 20px;
}
.portraitListingHeader {
  margin-right: 0.5em;
  height: 40px;
  width: 40px;
}
.preview {
  border: 1px solid white;
  outline: 1px solid #e3e3e3;
}
.portraitSquare {
  border-radius: 25%;
}
.portraitDropShadow {
  border: 2px solid #ffffff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}
.portraitDropShadow:hover {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}
.portraitContainer {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}
.portraitIndicator {
  position: absolute;
  width: 50%;
  height: 50%;
  bottom: 0;
  left: 0;
  stroke: white;
  stroke-width: 3;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.15));
}
.portraitIndicatorWhite {
  fill: white;
  stroke: var(--todovu-blue-200);
}
.portraitIndicatorGreen {
  fill: var(--todovu-green);
  stroke: white;
}
.portraitIndicatorOrange {
  fill: #FFA000;
  stroke: white;
}
.portraitIndicatorRed {
  fill: var(--todovu-red-300);
  stroke: white;
}
.portraitLarge .portraitIndicator {
  width: 30%;
  height: 30%;
}
.teamPortraitsOverflow {
  color: white;
  display: flex;
  z-index: 0;
  font-size: 12px;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  justify-content: center;
  background-color: #D0D0D0;
  transition: all 0.3s;
}
.teamPortraitsOverflow:hover {
  background-color: var(--todovu-primary-color);
}
.card-section-inverted {
  background-color: var(--todovu-primary-color);
  color: white;
}
.card-section-inverted .item-header-subheading {
  color: white;
}
.card-section-inverted .menu-icon {
  fill: white;
}
.view-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 67px;
  padding: 16px 20px 16px 4em;
  transition: all 0.5s;
}
.view-header-section {
  align-items: center;
  display: flex;
  fill: white;
  vertical-align: middle;
}
.view-header-section-left {
  display: flex;
  flex-direction: column;
  fill: white;
  vertical-align: middle;
}
.view-header-spacer {
  flex-grow: 1;
  flex-shrink: 1;
}
.view-header-section-right {
  align-items: center;
  display: flex;
  fill: white;
  vertical-align: middle;
  justify-content: flex-end;
}
.view-header-title {
  color: var(--todovu-primary-color);
  font-size: 20px;
  line-height: 1.3;
}
.view-header-subtitle {
  font-size: 16px;
}
.view-header-description {
  font-size: 14px;
  color: #808080;
}
.view-header-section-subtitle {
  align-items: center;
  display: flex;
  fill: white;
  vertical-align: middle;
  flex-basis: 100%;
  margin-top: 12px;
  margin-bottom: 5px;
  width: 100%;
}
.view-header-total-count {
  color: #999999;
  font-size: 14px;
  line-height: 1;
  padding-left: 1em;
}
.view-header-button {
  background-color: var(--todovu-primary-color);
  border: none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  fill: #ffffff;
  padding: 10px 30px 10px 30px;
  stroke: #ffffff;
  -webkit-tap-highlight-color: #0090bd;
  text-decoration: none;
  font-size: 15px;
  line-height: 1;
  padding: 9px 28px 9px 13px;
  text-align: center;
}
.view-header-button:active {
  background-color: #0090bd;
}
.view-header-button[disabled] {
  background-color: var(--todovu-primary-color);
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.view-header-button[disabled]:hover {
  background-color: var(--todovu-primary-color);
}
.view-header-button:hover,
.view-header-button:focus {
  background-color: #0090bd;
  color: #ffffff;
  text-decoration: none;
}
.view-header-button:not(:last-child) {
  margin-right: 10px;
}
.view-header-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.view-header-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.view-header-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.view-header-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.view-header-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.view-header-button.inline-block {
  display: inline-block;
}
.view-header-button:hover {
  background-color: #0090BD;
}
.view-header-button-text {
  margin-left: 20px;
  vertical-align: middle;
}
.view-header-separator:before {
  color: #e3e3e3;
  content: '|';
  font-size: 16px;
}
.view-header-separator:not(:last-child) {
  margin-right: 10px;
}
.view-toolbar {
  box-shadow: 0px 2px 5px 0px #efefef;
  border-bottom: 1px solid #cccccc;
  display: flex;
  height: 35px;
  justify-content: space-between;
  position: relative;
}
.view-toolbar-section-left {
  display: flex;
}
.view-toolbar-section-right {
  display: flex;
}
.view-toolbar-section-right > * {
  border-left: 1px solid #cccccc;
}
/* for grouping elements within a section */
.view-toolbar-subsection {
  display: flex;
}
.view-toolbar-checkbox {
  padding: 11px 20px;
}
.view-toolbar-link {
  border: 1px solid transparent;
  line-height: 35px;
  padding: 0px 8px;
  /* if it's the first child then we can assume its parent
       will have border to its left, so don't show another */
  /* if it's the last child then we can assume its parent
       will have border to its right, so don't show another */
}
.view-toolbar-link:hover {
  background-color: #f2f2f2;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}
.view-toolbar-link:first-child:hover {
  border-left: 1px solid transparent;
}
.view-toolbar-link:last-child:hover {
  border-right: 1px solid transparent;
}
.view-toolbar-menu-button-container {
  color: #8c8c8c;
  cursor: pointer;
  fill: #999999;
  stroke: transparent;
}
.view-toolbar-menu-button-container:hover {
  background-color: #f2f2f2;
}
.view-toolbar-menu-button {
  height: 35px;
  line-height: 35px;
  padding: 0px 8px;
}
.view-toolbar-menu-button .lazy-dropdown-button-title {
  color: #00a3d6;
  font-size: 14px;
}
.timeline-expand-overlay {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  opacity: 0;
  cursor: pointer;
}
.timeline-expand-overlay:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1);
}
.kitovu-background-color {
  background-color: var(--todovu-background-color);
}
.page-container {
  position: fixed;
  top: 60px;
  left: 60px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
}
.task-details-container {
  position: fixed;
  top: 60px;
  left: 60px;
  right: 0;
  bottom: 0;
  display: none;
  overflow: auto;
}
.sidebar-toggle-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s;
}
.sidebar-toggle-container .sidebar-icon {
  width: 24px;
  height: 24px;
  fill: white;
}
.sidebar-toggle-container:hover {
  opacity: 1;
}
.left-column {
  background-color: var(--todovu-background-color);
  position: relative;
  width: var(--todovu-left-column-width);
  z-index: 99;
  flex-grow: 0;
  flex-shrink: 0;
}
.column-header {
  flex-shrink: 0;
}
.column-body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.column-body .content {
  padding-top: 30px;
  padding-bottom: 2.5em;
}
.left-column-portlets {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-right: var(--todovu-tile-border);
}
.your-time-left-column .table-condensed {
  margin: auto;
}
.content-container {
  position: relative;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}
.content-container > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px;
}
#rProjectDetails-view,
#rTaskDetails-view {
  padding-left: 90px;
}
#rTasksListing-view,
#rLabelDashboard-view {
  padding: 0;
}
.main-page-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.fixed-page-header {
  flex-shrink: 0;
}
.scrollable-page-body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  /*
    without this the browser sometimes makes the div 0px height
    - but only sometimes. We also tried 100% but it lead to the bottom
    not being visible
    */
  min-height: 100px;
}
.portlet-title {
  color: #888888;
  padding: 1.75em 0 0.25em 10px;
  border-left: 5px solid transparent;
  font-size: 11px;
  text-transform: uppercase;
}
.portlet-title-heading {
  height: 40px;
  line-height: 40px;
  padding: 10px 15px;
  font-size: 20px;
  font-size: 22px;
  color: var(--todovu-primary-color);
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.portlet-title-heading a {
  color: var(--todovu-primary-color);
}
.portlet-title-heading a:hover {
  color: var(--todovu-secondary-color);
  text-decoration: none;
}
.portlet-title-large {
  border-left: 5px solid transparent;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
}
.portlet-title-link {
  color: #333333;
  font-size: 18px;
  display: inline-block;
}
.portlet-title-link:hover {
  color: #333333;
}
.portlet-title-count {
  display: block;
  float: right;
  opacity: 0.5;
  text-align: right;
}
.portlet-item-container {
  margin: 0;
}
.portlet-item {
  clear: both;
  margin: 0px;
}
.portlet-item-keyboard-selected {
  background-color: #F0F0F0;
}
.portlet-item-link {
  border-left: 5px solid transparent;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 11px;
  font-size: 16px;
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.portlet-item-link:hover,
.portlet-item-link:focus,
.portlet-item-link:active {
  color: 'var(--todovu-primary-color)';
  text-decoration: none;
}
.portlet-item-selected {
  border-left: 5px solid var(--todovu-primary-color);
  color: var(--todovu-primary-color);
}
.portlet-item-selected:hover {
  text-decoration: none;
}
.portlet-item-selected .portlet-title-link {
  color: #325bff;
}
.portlet-item-unselectable {
  /* unselectable portlet items display a X at the right on hover */
  position: relative;
}
.portlet-item-unselectable:hover::after {
  content: "×";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  color: var(--todovu-primary-color);
  background-color: var(--todovu-background-color);
  padding-right: 16px;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.portlet-item-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 8px;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
}
.portlet-item-name-wider {
  max-width: 215px;
}
.portlet-item-bold {
  /* that's ok */
  font-weight: 500;
}
.fb-like,
.fb-share-button {
  /* for some reason the buttons sit low in
       Mobile Safari/ Chrome without this */
  display: inline-block !important;
  /* by default the alignment is different to the Twitter
       button, so make them have the same alignment */
  vertical-align: top;
  /* force Facebook's iframe to inherit its visiblity
       setting - this is to make sure the Facebook buttons
       don't remain visible in spite of the parent's
       visiblity setting (see startup.js) */
}
.fb-like iframe,
.fb-share-button iframe {
  visibility: inherit !important;
}
.twitter-share-button {
  visibility: inherit !important;
}
.link-like {
  color: var(--todovu-primary-color);
  cursor: pointer;
}
.link-like:hover,
.link-like:focus {
  color: var(--todovu-secondary-color);
  text-decoration: none;
}
.nowrap {
  white-space: nowrap;
}
.clickable {
  cursor: pointer;
}
.clickable:hover,
.clickable:focus {
  color: var(--todovu-secondary-color);
  text-decoration: none;
}
input.error,
textarea.error {
  border-color: #e9322d;
  box-shadow: 0px 0px 6px #f8b9b7;
}
.modal-backdrop {
  background-color: white !important;
}
.your-settings-modal {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 12px 16px 16px 16px;
}
.your-settings-modal p {
  color: #999999;
  white-space: normal;
}
.your-settings-modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.your-settings-modal-header button {
  background-color: transparent;
  border: 0px;
}
.your-settings-modal-header-icon {
  height: 14px;
  stroke: #999999;
  width: 14px;
}
.your-settings-modal-title {
  font-size: 26px;
  line-height: normal;
  margin: 0px;
}
.your-settings-modal-footer {
  display: block;
  margin-top: 16px;
}
.your-settings-modal-footer.center {
  text-align: center;
}
.your-settings-modal-footer.right {
  text-align: right;
}
.your-settings-feedback-modal {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 12px 16px 16px 16px;
  box-sizing: border-box !important;
  margin-left: -260px !important;
  width: 520px !important;
}
.your-settings-feedback-modal p {
  color: #999999;
  white-space: normal;
}
.your-settings-feedback-inputs {
  margin-top: 10px;
}
.your-settings-feedback-inputs textarea {
  height: 160px;
}
.your-settings-feedback-inputs > * {
  border: 1px solid #cccccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: black;
  display: block;
  line-height: normal;
  padding: 6px 14px;
  width: 100%;
}
.your-settings-feedback-inputs > *:not(:last-child) {
  margin-bottom: 14px;
}
.screen-overlay {
  background-color: rgba(0, 0, 0, 0);
  display: none;
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 400;
}
/*
adds a faint shadow at the bottom of an element, indicating that the
element is fixed and other content scrolls below it
*/
.shadow-bottom {
  box-shadow: 0px 2px 5px 0px #efefef;
}
.btn.btn-nanolink {
  padding: 0;
}
.flash-message {
  border-radius: 2px;
  color: #000000;
  display: inline-block;
  height: 28px;
  line-height: 28px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  z-index: 10000;
}
.flash-message-normal {
  background-color: #fff1a4;
}
.flash-message-remote {
  background-color: #d0edff;
}
.flash-message-error {
  background-color: #ef9e62;
}
.flash-message-text {
  display: inline-block;
  max-width: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flash-message-item-name {
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-count {
  background-color: #f1271d;
  border-radius: 50%;
  font-size: 14px;
  color: white;
  padding: 0px 5px 0px 4px;
  white-space: nowrap;
}
.generic-settings-section {
  padding: 38px 59px 38px 59px;
}
.input-row {
  align-items: center;
  display: flex;
  margin: 8px 0px;
}
.input-label {
  flex-shrink: 0;
  padding-right: 25px;
  text-align: right;
  width: 140px;
}
.input-content input {
  line-height: normal !important;
}
.user-info {
  display: flex;
  padding: 33px 38px;
}
.user-name {
  font-size: 24px;
  line-height: 0.9;
  margin-bottom: 8px;
}
.user-settings {
  margin-left: 50px;
  width: 500px;
}
.user-upload {
  padding-top: 8px;
  margin-left: -13px;
}
.user-upload-icon {
  fill: #828282;
  height: 20px;
  margin-right: 5px;
  vertical-align: -4px;
  width: 20px;
}
::-ms-clear {
  display: none;
}
::-webkit-file-upload-button {
  cursor: pointer;
}
input::-webkit-input-placeholder {
  line-height: normal;
}
:focus {
  outline: none;
}
svg:not(:root) {
  overflow: hidden;
}
input[type=search] {
  -webkit-appearance: none;
}
main {
  display: block;
}
.collapse-element {
  border: 0px hidden transparent;
  height: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  visibility: hidden;
  width: 0px !important;
}
.hide,
.hidden {
  display: none;
}
.reset-button {
  background-color: transparent;
  border: 0px;
  padding: 0px;
}
.word-wrap {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
/******************/
/* flex utilities */
/******************/
.center-children {
  display: flex;
  align-items: center;
  justify-content: center;
}
.equal-spaced-children {
  display: flex;
  justify-content: space-between;
}
.horizontally-center-children {
  display: flex;
  justify-content: center;
}
.vertical-center-children {
  align-items: center;
  display: flex;
}
/******************
 * list utilities *
 ******************/
/* a list where the bullet touches the left border */
.list-left-aligned {
  margin: 0px !important;
}
.list-left-aligned li {
  list-style-position: inside;
}
.list-square-dots {
  list-style-type: square;
}
/*******************/
/* modal utilities */
/*******************/
/***************************/
/* selectability utilities */
/***************************/
.content-selectable {
  -webkit-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: auto;
  user-select: auto;
}
.content-unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*******************/
/* touch utilities */
/*******************/
/**********************/
/* triangle utilities */
/**********************/
.base-triangle {
  border-style: solid;
  content: '';
  display: block;
  height: 0px;
  position: absolute;
  width: 0px;
}
/* Bootstrap Overrides */
/* todo.vu Specific */
/* What's the point of using LESS if our stylesheet is polluted by magical pixel values? */
/* the min width after which horizontal scrollbars should appear */
.md-text-display1 {
  font-size: 34px;
  font-weight: normal;
}
.md-text-headline {
  font-size: 24px;
  font-weight: normal;
}
.md-text-title {
  font-size: 20px;
  font-weight: 500;
}
.md-text-subheading {
  font-size: 16px;
  font-weight: normal;
}
.md-simple-menu-body {
  border: 0;
  border-radius: var(--todovu-card-border-radius);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
  padding: 8px 0;
}
.md-simple-menu-item {
  color: #333333;
  line-height: 48px;
  padding: 0 16px;
  transition: background-color 0.3s;
}
.md-simple-menu-item-hover {
  background-color: #ebebeb;
  background-image: none;
  color: #222222;
  text-decoration: none;
}
/* bootstrap overrides for dropdown menu */
.dropdown-menu {
  border: 0;
  border-radius: var(--todovu-card-border-radius);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
  padding: 8px 0;
}
.dropdown-menu li a {
  color: #333333;
  line-height: 48px;
  padding: 0 16px;
  transition: background-color 0.3s;
}
.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
  background-color: #ebebeb;
  background-image: none;
  color: #222222;
  text-decoration: none;
}
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: #ebebeb;
  background-image: none;
  color: #222222;
  text-decoration: none;
}
.dropdown-submenu > a:after {
  margin-top: 20px;
}
/* /end of bootstrap overrides for dropdown menu */
.md-card {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.26);
  border-radius: var(--todovu-card-border-radius);
  transition: box-shadow 0.3s;
}
.md-card-raised {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
}
.md-text-headline {
  font-size: 24px;
  line-height: 32px;
}
.kitovu-flat-button {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  min-width: 64px;
  height: 36px;
  line-height: 36px;
  padding: 0 8px;
  margin: 8px;
  background-color: transparent;
  transition: background-color 0.3s;
}
.kitovu-flat-button:hover {
  background-color: #EEEEEE;
  text-decoration: none;
}
.kitovu-button-danger {
  color: #f44336;
}
.btn {
  padding-top: 0;
  padding-bottom: 0;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  line-height: 36px;
  padding: 0 8px;
  transition: background-color 0.3s;
  background-image: none;
  white-space: nowrap;
  text-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 6px;
}
.btn i {
  vertical-align: middle;
}
.btn svg {
  width: 16px;
  height: 16px;
}
.btn-vmargin {
  margin-top: 8px;
  margin-bottom: 8px;
}
.btn-hmargin {
  margin-left: 8px;
  margin-right: 8px;
}
.btn-margin {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.btn-icon {
  box-sizing: border-box;
  min-width: 36px;
  text-align: center;
  border-radius: 50%;
}
.btn-icon svg {
  width: 20px;
  height: 16px;
}
.btn-icon-round {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  min-width: 48px;
  padding: 12px;
  border-radius: 50%;
}
.btn-icon-round svg {
  width: 24px;
  height: 24px;
}
.btn-nomargin {
  margin: 0;
}
.btn-md-raised {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.26);
  transition: box-shadow 0.3s;
}
.btn-md-raised:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
}
.btn-md-raised:active {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.26);
}
.btn-link:hover {
  text-decoration: none;
  outline: none;
  background-color: rgba(153, 153, 153, 0.2);
}
.btn-link:focus {
  text-decoration: none;
  outline: none;
  background-color: rgba(153, 153, 153, 0.4);
}
.md-btn-inverse {
  color: rgba(255, 255, 255, 0.54);
  fill: rgba(255, 255, 255, 0.54);
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.5s, color 0.5s;
}
.md-btn-inverse:hover,
.md-btn-inverse:focus {
  color: #ffffff;
  fill: #ffffff;
  background-color: rgba(255, 255, 255, 0.25);
  transition: background-color 0.5s, color 0.5s;
}
.md-btn-inverse[disabled]:hover,
.md-btn-inverse[disabled]:focus {
  color: rgba(255, 255, 255, 0.38);
  fill: rgba(255, 255, 255, 0.38);
  text-decoration: none;
  cursor: default;
}
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.btn-nocaps {
  text-transform: none;
  font-weight: normal;
}
.btn-margin-compensate {
  margin-left: -8px;
}
.md-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1px;
  transition: border-bottom 0.3s;
}
.md-input-active {
  border: none;
  border-bottom: 2px solid var(--todovu-primary-color);
}
input.search-query {
  margin-bottom: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  border: none;
  background: rgba(255, 255, 255, 0.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUIASUOYamVGQAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAABEElEQVQoz43SvyvFYRTH8Re++TGgm8Vi82MSgzJyYzEqZCDKXyDhT1DqFgMb5Yo7mNluoSzKwqSkLFeyGEwIy1Ff367yrqfzqef5nHN6zqkpl8uCHixiGK14wgk2Q/8iiTiOHeRSd+3owySmcZU21qIbe2G6xgLyWEIFnSihJVtxBc24wRBe4u4UxzhDF2axla6YD72eMv1wG93AWLbV1tB3qnMfsS1rfA498IexP2IlazwOvRoflWYEM/jEfvZzCphCB85RxAN6MYdGfKA+a3zERGTsxnKVdpNI+IWj9AJcYhDzGI2ZVuJRDttowCGaUExSWV+wESfLa4wlwS7qa/2Pg1iAN9ShkPg/JbxjDRff9083RZ2YGEwAAAAASUVORK5CYII=) no-repeat center;
  background-position: 20px center;
  /* Safari-Specific positioning */
  height: 36px;
  line-height: normal;
  margin: 0;
  padding: 4px 20px 4px 50px;
  width: 100%;
  outline: 0;
}
input.search-query:focus {
  border: none;
  box-shadow: none;
  outline: 0;
}
.kitovu-tile-container {
  display: flex;
  flex-wrap: wrap;
}
.kitovu-tile {
  height: 180px;
  box-sizing: border-box;
  width: 25%;
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 0;
  opacity: 0.8;
  transition: 0.5s;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
}
.kitovu-tile:hover {
  opacity: 1;
  background-color: #EEEEEE;
  text-decoration: none;
}
.kitovu-tile-header {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}
.md-tab-container {
  display: flex;
  justify-content: center;
  border-bottom: none;
}
.md-tab {
  display: block;
}
.md-tab-link {
  box-sizing: border-box;
  min-width: 160px;
  height: 48px;
  padding: 12px;
  text-align: center;
  margin-right: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}
.md-tab-link-active {
  color: var(--todovu-primary-color);
  border: 0;
  border-bottom: 2px solid var(--todovu-primary-color);
}
/* bootstrap overrides for tabs */
.nav-tabs {
  display: flex;
  justify-content: center;
  border-bottom: none;
}
.nav-tabs li {
  display: block;
}
.nav-tabs li a {
  box-sizing: border-box;
  min-width: 160px;
  height: 48px;
  padding: 12px;
  text-align: center;
  margin-right: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}
.nav-tabs li a:hover,
.nav-tabs li a:active,
.nav-tabs li a:focus {
  color: var(--todovu-primary-color);
  border: 0;
  border-bottom: 2px solid var(--todovu-primary-color);
}
.nav-tabs li.active a,
.nav-tabs li.active a:hover,
.nav-tabs li.active a:active,
.nav-tabs li.active a:focus {
  color: var(--todovu-primary-color);
  border: 0;
  border-bottom: 2px solid var(--todovu-primary-color);
}
.muted {
  color: rgba(0, 0, 0, 0.38);
}
.whitespace-nowrap {
  white-space: nowrap;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slightly-muted {
  color: rgba(0, 0, 0, 0.54);
}
/* end of bootstrap overrides for tabs */
/* bootstrap overrides for popovers */
.popover {
  border-radius: 2px;
  border: 0;
  background-color: #323232;
}
/* left */
.popover.left .arrow {
  border-left-color: transparent;
}
.popover.left .arrow:after {
  border-left-color: #323232;
}
/*bottom */
.popover.bottom .arrow {
  border-bottom-color: transparent;
}
.popover.bottom .arrow:after {
  border-bottom-color: #323232;
}
.popover-title {
  color: white;
  background: transparent;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.36);
}
.popover-content {
  color: white;
}
/* end of bootstrap overrides for popovers */
.md-input-size-mini {
  width: 60px;
}
.md-input-size-small {
  width: 90px;
}
.md-input-size-medium {
  width: 150px;
}
.md-input-size-large {
  width: 210px;
}
.md-input-size-xlarge {
  width: 270px;
}
.md-input-size-xxlarge {
  width: 530px;
}
.md-input-container {
  position: relative;
  margin-top: 24px;
  margin-bottom: 4px;
}
textarea.md-input-input,
input.md-input-input {
  box-shadow: none;
  transition: none;
  margin-bottom: 0;
  border-radius: 0;
  font-size: 16px;
  padding: 8px 0 8px 0;
  display: block;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
textarea.md-input-input:focus,
input.md-input-input:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid transparent;
}
textarea.md-input-input:focus:invalid:focus,
input.md-input-input:focus:invalid:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid transparent;
}
/* LABEL ======================================= */
.md-input-label {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* Label in the active state */
.md-input-input:focus ~ .md-input-label,
.md-input-input.md-input-full ~ .md-input-label {
  top: -16px;
  font-size: 12px;
  color: var(--todovu-primary-color);
}
/* BOTTOM BARS ================================= */
.md-input-bar {
  position: relative;
  display: block;
}
.md-input-bar:before,
.md-input-bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--todovu-primary-color);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.md-input-bar:before {
  left: 50%;
}
.md-input-bar:after {
  right: 50%;
}
/* active state */
.md-input-input:focus ~ .md-input-bar:before,
.md-input-input:focus ~ .md-input-bar:after {
  width: 50%;
}
.md-input-input:invalid ~ .md-input-label {
  color: var(--todovu-red);
}
.md-input-input:invalid ~ .md-input-bar:before,
.md-input-input:invalid ~ .md-input-bar:after {
  background: var(--todovu-red);
}
/* disabled input styles */
textarea.md-input-input[disabled],
input.md-input-input[disabled] {
  background-color: transparent;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.38);
}
textarea.md-input-input[disabled] ~ .md-input-label,
input.md-input-input[disabled] ~ .md-input-label {
  color: rgba(0, 0, 0, 0.38);
}
/* HIGHLIGHTER ================================== */
.md-input-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.md-input-input:focus ~ .md-input-highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: var(--todovu-primary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: var(--todovu-primary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: var(--todovu-primary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
.md-select {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  box-shadow: none;
  outline: none;
  transition: border-bottom 0.3s;
  margin-bottom: 0;
  padding: 0;
}
.md-select option {
  padding: 8px;
}
.md-select:focus {
  outline: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid var(--todovu-primary-color);
}
input,
textarea {
  box-shadow: none!important;
}
.btn-md-themed.btn-primary {
  background-color: var(--todovu-primary-color);
  transition: all 0.3s;
}
.btn-md-themed.btn-primary:hover {
  background-color: var(--todovu-primary-color);
  filter: brightness(1.2);
}
.btn-md-themed.btn-link {
  color: var(--todovu-primary-color);
  transition: all 0.3s;
}
.btn-md-themed.btn-link:hover {
  color: var(--todovu-primary-color);
  filter: brightness(0.8);
}
.reveal-on-hover-container .reveal-on-hover-item {
  opacity: 0;
  transition: 0.3s;
}
.reveal-on-hover-container:hover .reveal-on-hover-item {
  opacity: 1;
}
html,
body {
  color: #c1c4c5;
  margin: 0px;
  height: 100%;
}
form {
  margin-bottom: 0;
}
.external-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #41494e;
}
.external-page-with-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(65, 73, 78, 0.8);
  background-size: cover;
  position: relative;
}
.external-page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.external-page-header {
  margin-bottom: 15px;
  text-align: center;
}
.external-page-header strong {
  display: block;
}
.external-page-body {
  background-color: #4b5257;
  border-radius: 2px;
  padding: 32px;
  text-align: center;
  max-width: 400px;
  box-shadow: 0 24px 24px rgba(0, 0, 0, 0.3);
}
.external-page-body-transparent {
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 6px;
  padding: 32px;
  text-align: center;
  max-width: 400px;
  box-shadow: 0 24px 24px rgba(0, 0, 0, 0.3);
}
.external-submit-button {
  background-color: #e95645;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  fill: white;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: white;
  -webkit-tap-highlight-color: #e95645;
  text-align: center;
  text-decoration: none;
  /* suppress mobile Safari's default styling behaviour */
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  margin: 0px 58px 15px 58px !important;
  padding-top: 8px 0px;
  white-space: nowrap;
  width: 296px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.54);
  transition: all 0.3s;
}
.external-submit-button:active {
  background-color: #e95645;
}
.external-submit-button[disabled] {
  background-color: #e95645;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.external-submit-button[disabled]:hover {
  background-color: #e95645;
}
.external-submit-button:hover,
.external-submit-button:focus {
  background-color: #e95645;
  color: white;
  text-decoration: none;
}
.external-submit-button:not(:last-child) {
  margin-right: 10px;
}
.external-submit-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.external-submit-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.external-submit-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.external-submit-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.external-submit-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.external-submit-button.inline-block {
  display: inline-block;
}
.external-submit-button:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.54);
  transform: translate(-1px, -1px);
}
.google-login-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
}
.google-login-or {
  margin-top: 24px;
  text-align: center;
  font-style: italic;
  color: rgba(255, 255, 255, 0.54);
}
.google-login-button {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  stroke: #ffffff;
  -webkit-tap-highlight-color: #FFFFFF;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  padding: 11px 8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.54);
  transition: all 0.3s;
}
.google-login-button:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.54);
  transform: translate(-1px, -1px);
}
.google-login-icon {
  height: 18px;
  width: 18px;
  stroke: none;
  margin-right: 24px;
}
.submit-button {
  background-color: #e95645;
  border: none;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  fill: white;
  line-height: 1;
  padding: 10px 30px 10px 30px;
  stroke: white;
  -webkit-tap-highlight-color: #e95645;
  text-align: center;
  text-decoration: none;
}
.submit-button:active {
  background-color: #e95645;
}
.submit-button[disabled] {
  background-color: #e95645;
  color: black;
  cursor: default;
  /* if it's disabled, hover shouldn't do anything */
}
.submit-button[disabled]:hover {
  background-color: #e95645;
}
.submit-button:hover,
.submit-button:focus {
  background-color: #e95645;
  color: white;
  text-decoration: none;
}
.submit-button:not(:last-child) {
  margin-right: 10px;
}
.submit-button.left {
  border-radius: 0px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-right: 1px;
}
.submit-button.right {
  border-radius: 0px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.submit-button.narrow {
  padding-left: 16px;
  padding-right: 16px;
}
.submit-button.wide {
  padding-left: 45px;
  padding-right: 45px;
}
.submit-button.short {
  padding-top: 5px;
  padding-bottom: 5px;
}
.submit-button.inline-block {
  display: inline-block;
}
body {
  background-color: #41494e;
  color: #c1c4c5;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
}
p {
  line-height: 1.3;
}
input {
  display: block;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  margin: auto;
  padding: 0px;
}
.external-page-link {
  color: white;
}
.external-page-link:hover,
.external-page-link:focus {
  color: white;
}
.external-page-url {
  color: #c1c4c5;
}
.external-page-url:hover,
.external-page-url:active {
  color: white;
}
.external-page-url-plain {
  color: #c1c4c5;
  text-decoration: none;
}
.external-page-url-plain:hover,
.external-page-url-plain:active {
  color: white;
}
.login-page-header-image {
  margin-bottom: 16px;
  opacity: 0.54;
}
.login-page-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.54);
  margin-top: 34px;
  padding-top: 22px;
}
.login-page-error-message {
  background-color: #fff1a4;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 18px;
  margin-left: 58px;
  margin-right: 58px;
  padding: 7px;
  width: 282px;
}
.reset-page-error-message {
  background-color: #fff1a4;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 18px;
  margin-left: 58px;
  margin-right: 58px;
  padding: 7px;
  width: 282px;
  display: block;
  margin-top: 5px;
  margin-bottom: 15px;
}
.reset-page-message {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  width: 410px;
}
.browser-select td {
  padding: 1em 1em 0 1em;
}
.browser-select a {
  color: white;
  text-decoration: none;
}
.password-update-message-container {
  margin-top: 29px;
}
.password-updated-message-container {
  margin-bottom: 44px;
  margin-top: 36px;
}
.error-message-container {
  margin-bottom: 29px;
}
#error-container a {
  color: #ffffff;
  text-decoration: underline;
}
#error-container {
  color: #ffffff;
  display: inline-block;
  font-family: Arial, sans-serif;
}
.error-heading {
  background: #545c60;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  padding: 8px 19px;
}
.error-title {
  font-size: 34px;
}
.error-text {
  line-height: 1.3;
}
.no-stupidly-long-strings {
  display: inline-block;
  max-width: 15em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
